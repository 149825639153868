import React, { useState, useEffect, useMemo } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import Select from "react-select";
import TeamAddModal from "../teamAdd";
import TeamEditModal from "../teamEdit";
import TeamDeleteModal from "../teamDelete";
import { getRawOrgInfo } from "../../hooks/orgInfo";
import Loader from "../../common/loader";
import { showLoader, hideLoader } from "../../store/loadingSlice";
import MessagePopup from "../../components/messagePopup";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { editTeam } from "../../hooks/teamEdit";
import config from "../../config.js";
import alertManagementArrow from "../../assets/alt-mgt-ic.svg";
import collapsedArrowIcon from "../../assets/collapsed-arrow.svg";
import { FaCircleCheck } from "react-icons/fa6";

const OrganizationOverview = ({ activeTab, editTeamId, setEditTeamId }) => {
  const [orgInfo, setOrgInfo] = useState(
    useSelector((state) => state.org.orgData)
  );

  const userInfo = useSelector((state) => state.user.userInfo) || {};
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [errors, setErrors] = useState({});
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [grafanaId, setGrafanaId] = useState(null);
  const [collapsedTeams, setCollapsedTeams] = useState({});
  // const [editTeamId, setEditTeamId] = useState(null);
  const [teams, setTeams] = useState(orgInfo.teams || []);
  const [currentTeam, setCurrentTeam] = useState("");
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  const sortedTeams = useMemo(
    () => [...teams]?.sort((a, b) => a.teamName.localeCompare(b.teamName)),
    [teams]
  );

  const handleToggleCollapse = (teamId) => {
    setCollapsedTeams((prevState) => ({
      ...prevState,
      [teamId]: !prevState[teamId],
    }));
  };

  const dispatch = useDispatch();
  const { id } = useParams();
  const getTimezones = () => {
    return moment.tz.names().map((timezone) => ({
      value: timezone,
      label: timezone,
    }));
  };
  const timezones = getTimezones();

  const updateMessage = (type, content) => {
    setMessageType(type);
    setMessage(content);
  };

  const handleGrafana = () => {
    localStorage.setItem(
      "activePath",
      `/organization/${id}?activeTab=overview`
    );
    window.location.href = `${config.apiUrl}/grafana_oidc/${grafanaId}`;
  };

  const getOrgInfo = () => {
    dispatch(showLoader());
    getRawOrgInfo()
      .then((response) => {
        let orgData = response.data?.find(
          (item) => item.orgId === parseInt(id, 10)
        );
        if (orgData) {
          setOrgInfo(orgData);
          setTeams(() => orgData.teams || []);
          setGrafanaId(orgData.grafanaId);
        }
        dispatch(hideLoader());
      })
      .catch((error) => {
        console.error("Failed to fetch organizations", error);
        dispatch(hideLoader());
      });
  };

  const handleChange = (e, teamId, field) => {
    const { value } = e.target;
    if (field === "teamName") {
      setCurrentTeam(() => e.target.value);
    }
    if (field === "timezone") {
      setTeams((prevTeams) =>
        prevTeams.map((team) =>
          team.teamId === teamId ? { ...team, [field]: value } : team
        )
      );
    }
    setIsFormChanged(true);
    // Clear error for the specific field
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  const validateTeam = () => {
    const team = teams.find((t) => t.teamId === editTeamId);
    const newErrors = {};

    if (!team.teamName) {
      newErrors.teamName = "Team name is required.";
    }
    if (!team.timezone) {
      newErrors.timezone = "Timezone is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = (teamId, field) => {
    if (!isFormChanged) {
      setEditTeamId(null);
      return;
    }
    if (validateTeam()) {
      let updatedTeam = teams.find((team) => team.teamId === editTeamId);
      // setCurrentTeam(currentTeam.trim());
      updatedTeam = {
        team_id: updatedTeam.teamId,
        // name: updatedTeam.teamName,
        name: currentTeam.trim(),
        timezone: updatedTeam.timezone,
      };
      console.log("updatedTeam.name", updatedTeam.name);
      if (!updatedTeam.name.trim()) {
        // setError("Team name is required.");
        setErrors((prevErrors) => ({
          ...prevErrors,
          ["teamName"]: "Team name is required.",
        }));
        return;
      }
      dispatch(showLoader());
      setIsSubmitting(true);
      editTeam(updatedTeam)
        .then(() => {
          dispatch(hideLoader());
          updateMessage(
            "success",
            "Success! The Team has been Updated successfully"
          );
          setTeams((prevTeams) =>
            prevTeams.map((team) =>
              team.teamId === teamId ? { ...team, [field]: currentTeam.trim() } : team
            )
          );
          setEditTeamId(null);
          setIsSubmitting(false);
          getOrgInfo(); // Refresh organization data
          setIsFormChanged(false);
        })
        .catch((error) => {
          dispatch(hideLoader());
          console.error("Failed to update team", error);
          updateMessage("error", error.message);
          setIsSubmitting(false);
        });
    }
  };

  useEffect(() => {
    setGrafanaId(orgInfo.grafanaId);
  }, [orgInfo]);

  useEffect(() => {
    if (activeTab === "overview") {
      getOrgInfo();
    }
  }, [activeTab]);

  return (
    <>
      <MessagePopup
        message={message}
        messageType={messageType}
        onClose={() => setMessage("")}
      />
      {sortedTeams?.map((team) => (
        <>
          {team.teamName !== "Master Data Management" && (
            <div key={team.teamId} className="team-container">
              <div
                className="team-content-title"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {editTeamId === team.teamId ? (
                  <div className="edit-team-outer">
                    <div className="edit-team-left">
                      <div className="edit-fld-box">
                        <Form.Control
                          name="teamName"
                          value={currentTeam}
                          onChange={(e) =>
                            handleChange(e, team.teamId, "teamName")
                          }
                          placeholder="Enter Team Name"
                          isInvalid={!!errors.teamName}
                          className="team-name-input"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.teamName}
                        </Form.Control.Feedback>
                      </div>

                      <div className="team-time-select">
                        <Select
                          className={`time-select ${
                            errors.timezone ? "error" : ""
                          }`}
                          options={timezones}
                          value={timezones.find(
                            (tz) => tz.value === team.timezone
                          )}
                          onChange={(selectedOption) =>
                            handleChange(
                              { target: { value: selectedOption.value } },
                              team.teamId,
                              "timezone"
                            )
                          }
                          placeholder="Select Timezone"
                          classNamePrefix="custom"
                        />
                        {errors.timezone && (
                          <small
                            className="error-text"
                            style={{ color: "red" }}
                          >
                            {errors.timezone}
                          </small>
                        )}
                      </div>
                    </div>
                    {userInfo.role === "Ensaras Admin" &&
                      ![
                        "Engineer",
                        "Project Analytics",
                        "Master Data Management",
                        "Tech Stack",
                      ].includes(team?.teamName?.trim()) && (
                        <div className="edit-team-rgt">
                          <Button
                            variant="outline-secondary"
                            onClick={() => {
                              setShowDeleteModal(true);
                              setSelectedTeam(team);
                            }}
                          >
                            Delete Team
                          </Button>
                          <Button
                            onClick={() => handleSave(team.teamId, "teamName")}
                            variant="outline-secondary"
                            disabled={isSubmitting}
                          >
                            Finish Edit
                          </Button>
                        </div>
                      )}
                  </div>
                ) : (
                  <>
                    <h2>
                      <span
                        className="team-arrow"
                        onClick={() => handleToggleCollapse(team.teamId)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={
                            collapsedTeams[team.teamId]
                              ? collapsedArrowIcon
                              : alertManagementArrow
                          }
                          alt="Team Name Logo"
                        />
                      </span>{" "}
                      {team.teamName.length > 50
                        ? `${team.teamName.substring(0, 15)}...`
                        : team.teamName}{" "}
                      <span>({team.timezone})</span>
                    </h2>
                    {userInfo.role === "Ensaras Admin" &&
                      ![
                        "Engineer",
                        "Project Analytics",
                        "Master Data Management",
                        "Tech Stack",
                      ].includes(team?.teamName?.trim()) && (
                        <div
                          className={`edit-team-btn ${
                            collapsedTeams[team.teamId] ? "collapsed" : ""
                          }`}
                        >
                          {" "}
                          <Button
                            onClick={() => {
                              setEditTeamId(team.teamId);
                              setErrors({});
                              setCurrentTeam(() => team.teamName);
                            }}
                            variant="outline-primary"
                          >
                            Edit Team
                          </Button>
                        </div>
                      )}
                  </>
                )}
              </div>
              {!collapsedTeams[team.teamId] && (
                <>
                  <div className="team-button-list">
                    <button className="daskbtn" onClick={() => handleGrafana()}>
                      Dashboard
                    </button>
                    {team.forms && team.forms.length > 0 && (
                      <button
                        onClick={() =>
                          navigate(
                            `/alerts/${team.teamId}?orgId=${orgInfo.orgId}`
                          )
                        }
                        className="alt-btn"
                      >
                        Alert List
                      </button>
                    )}
                  </div>
                  <div className="submit-forms-mn">
                    <h4>Submit Forms</h4>
                    <div
                      className="submit-form-outer"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(4, 1fr)",
                        gap: "10px",
                      }}
                    >
                      {team.forms?.map((form) => (
                        <div className="submit-form-box" key={form.formId}>
                          <div className="team-title-btn">
                            <a
                              href={`/form/${form.formId}?orgId=${orgInfo.orgId}`}
                            >
                              {form.formName}
                            </a>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {(userInfo.role === "Ensaras Admin" ||
                    userInfo.role === "Client Admin") && (
                    <div className="submit-forms-mn">
                      <h4>Team Members</h4>
                      <div
                        className="submit-form-outer"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(4, 1fr)",
                          gap: "10px",
                        }}
                      >
                        {team.users?.map((user, idx) => (
                          <div className="submit-form-box" key={idx}>
                            {/* <div className="team-title-btn">{user.email}</div> */}
                            <div className="team-title-btn">
                              {user.email}{" "}
                              {/* {user.is_email_verified && (
                              <span className="verified-badge">
                                <FaCircleCheck />
                              </span>
                            )} */}
                              {user.is_email_verified ? (
                                <span>
                                  <FaCircleCheck className="input-icon verified" />
                                </span>
                              ) : (
                                <span className="pending-verification">
                                  <FaCircleCheck className="input-icon pending-verification" />
                                </span>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </>
      ))}
      {userInfo.role === "Ensaras Admin" && (
        <div className="team-container add-team">
          <span onClick={() => setShowAddModal(true)}> + Add Team</span>
        </div>
      )}

      <TeamAddModal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        orgId={orgInfo.orgId}
        getOrgInfo={getOrgInfo}
        updateMessage={updateMessage}
      />
      <TeamDeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        teamName={selectedTeam?.teamName}
        teamId={selectedTeam?.teamId}
        getOrgInfo={getOrgInfo}
        updateMessage={updateMessage}
      />
    </>
  );
};

export default OrganizationOverview;
