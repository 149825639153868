import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteConfirmModal = ({ show, onHide, onConfirm, itemName, moduleName }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete {moduleName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete <b>{itemName}</b>?
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={onHide}>
          Close
        </Button> */}
        <Button variant="primary" onClick={onConfirm} className="save-team-btn">
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmModal;