import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";

import { addOrg } from "../../hooks/orgAdd.js";
import "./style.css";

const OrgAddModal = ({ show, onHide, getOrgInfo, updateMessage }) => {
  const [orgName, setOrgName] = useState(null);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setOrgName(null);
    setError("");
    onHide();
  };

  const handleSubmit = () => {
    if (!orgName?.trim()) {
      // setError("Organization name is required.")
      setError("Org name is required.");
      return;
    }
    setOrgName(orgName.trim());
    setIsSubmitting(true);
    addOrg({ name: orgName })
      .then((response) => {
        // Close the modal
        handleClose();
        setIsSubmitting(false);
        getOrgInfo();
        updateMessage(
          "success",
          "Success! The Org has been created successfully"
        );
      })
      .catch((error) => {
        setIsSubmitting(false);
        updateMessage("error", error.message);
      });
  };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Create Org</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Org Name <span className="text-danger">*</span></Form.Label>
                        <div className="org-fld">
                        <Form.Control 
                            placeholder="Enter Org Name" 
                            isInvalid={error === "" ? false : true} 
                            value={orgName} 
                            onChange={(e) => setOrgName(e.target.value)} 
                            maxLength={100}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error}
                        </Form.Control.Feedback>
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                {/* <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button> */}
                <Button variant="primary" className='save-org-btn' onClick={handleSubmit} disabled={isSubmitting}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default OrgAddModal
