import React, { useState, useEffect } from "react";
import "./style.css";
import logo from "../../assets/logo.svg";
import logo1 from "../../assets/logo1.svg";
import tabIcon from "../../assets/tab-icon.svg";
import ResetPasswordModal from "../resetPassword";
import ResendEmailModal from "../resendEmail";
// import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { FaArrowLeft, FaBars } from "react-icons/fa6"; // Import icons
import { IoMdClose } from "react-icons/io";
// import Container from "react-bootstrap/Container";
// import Nav from "react-bootstrap/Nav";
// import Offcanvas from "react-bootstrap/Offcanvas";
import { FiAlignJustify } from "react-icons/fi";

import { Navbar, Container, Offcanvas, Nav, Dropdown } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import { signout } from "../../hooks/signout.js";
import { grafanaLogout } from "../../hooks/grafanaLogout.js";
import MessagePopup from "../messagePopup";
import OrgAddModal from "../orgAdd";

function AppNavBar({ getOrgInfo, needBack, setKey, activeKey, organization, backLink ,resetTabEdits, setEditMode }) {
  const [showSidebar, setShowSidebar] = useState(false); // State to control sidebar visibility
  const [isWideEnough, setIsWideEnough] = useState(window.innerWidth > 1000);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsWideEnough(window.innerWidth > 1000);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const handleLogout = async () => {
  //   grafanaLogout();
  //   await signout();
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("orgId");
  //   localStorage.removeItem("LastSubmittedData");
  //   localStorage.removeItem("refresh_token");
  //   window.location.href = "/signin";
  // };

const handleTabChange = (key) => {
    setKey(key);
    resetTabEdits();
}
useEffect(() => {
  const params = new URLSearchParams(location.search);
  const activeTab = params.get("activeTab");
  if (activeTab) {
    setKey(activeTab);
  }
}, [location.search, setKey]);

  const handleLogout = async () => {
    try {
      grafanaLogout();
      signout();
      localStorage.clear();
      window.location.href = "/signin";
    } catch (error) {
      console.error("Failed to logout", error);
    }
  };
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const closeSidebar = () => {
    setShowSidebar(false);
  };

  // message window content
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const updateMessage = (type, content) => {
    setMessageType(type);
    setMessage(content);
  };

  // handle reset password modal open/close
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const handleResetPasswordModal = (status) => {
    setShowResetPasswordModal(status);
    if(localStorage.getItem('activeTab')){
      resetTabEdits();
    }
    else{
      setEditMode(false);
    }
  };

  // handle resend email modal open/close
  const [showResendEmailModal, setShowResendEmailModal] = useState(false);
  const handleResendEmailModal = (status) => {
    setShowResendEmailModal(status);
  };

  // handle add org
  const [showOrgAddModal, setShowOrgAddModal] = useState(false);
  const handleOrgAddModal = (status) => {
    setShowOrgAddModal(status);
  };

  // change the layout by page width
  const [expand, setExpand] = useState(window.innerWidth > 1000);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1000) {
        setExpand("sm");
      } else {
        setExpand(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isWideEnough) {
    return (
      <div className="navbar-wrapper">
        {/* <MessagePopup
          message={message}
          messageType={messageType}
          onClose={() => setMessage("")}
        /> */}
        <Navbar key={expand} expand={expand} className="navbar-wrapper">
          <Container fluid>
            {needBack && (
              <a href="/" className="back-button">
                <FaArrowLeft /> Back
              </a>
            )}
            <div className="Navbar.Brand">
              <a href="/dashboard" className="logo">
                <img src={logo} alt="Ensaras Logo" className="logo desk-logo" />
                <img src={logo1} alt="Ensaras Logo" className="logo mob-logo" />
              </a>
            </div>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Tools
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {/* {localStorage.getItem("role") === "Ensaras Admin" && (
                  <button
                    className="nav-btn"
                    onClick={() => {
                      handleOrgAddModal(true);
                    }}
                  >
                    Create Org
                  </button>
                )} */}
                  <button
                    className="nav-btn"
                    onClick={() => handleResetPasswordModal(true)}
                  >
                    Reset Password
                  </button>
                  {/* {(localStorage.getItem("role") === "Ensaras Admin" ||
                  localStorage.getItem("role") === "Client Admin") && (
                  <button
                    className="nav-btn"
                    onClick={() => handleResendEmailModal(true)}
                  >
                    Resend Email
                  </button>
                )} */}
                  <button onClick={handleLogout} className="nav-btn">
                    Log out
                  </button>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>

        <ResetPasswordModal
          show={showResetPasswordModal}
          onHide={() => handleResetPasswordModal(false)}
          updateMessage={updateMessage}
        />

        <ResendEmailModal
          show={showResendEmailModal}
          onHide={() => handleResendEmailModal(false)}
          updateMessage={updateMessage}
        />

        <OrgAddModal
          show={showOrgAddModal}
          onHide={() => handleOrgAddModal(false)}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />
      </div>
    );
  } else {
    return (
      <div className="navbar-wrapper">
        {/* <MessagePopup
          message={message}
          messageType={messageType}
          onClose={() => setMessage("")}
        /> */}
        <Navbar key={expand} expand={expand} className="navbar-wrapper">
          <Container fluid>
            {organization && (
              <div className="mobile-menu mobile-tab">
                {((localStorage.getItem("role") !== "Client User" &&
                  localStorage.getItem("orgLength") > 1 )|| localStorage.getItem("role") === "Client Admin") && (
                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        <img
                          src={tabIcon}
                          alt="Menu"
                          style={{ width: "24px", height: "24px" }}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {(localStorage.getItem("orgLength") > 1 ||
                          localStorage.getItem("role") === "Ensaras Admin") && (
                          <Dropdown.Item onClick={() => {navigate("/dashboard"); localStorage.removeItem("activePath")}}>
                            Organization Name
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          onClick={() =>handleTabChange("overview")}
                          className={
                            activeKey === "overview" ? "active-tab" : ""
                          }
                        >
                          Organization Overview
                        </Dropdown.Item>
                        {(localStorage.getItem("role") === "Ensaras Admin" ||
                          localStorage.getItem("role") === "Client Admin") && (
                          <>
                            <Dropdown.Item
                              onClick={() => handleTabChange("account")}
                              className={
                                activeKey === "account" ? "active-tab" : ""
                              }
                            >
                              Account Management
                            </Dropdown.Item>
                            {localStorage.getItem("role") ===
                              "Ensaras Admin" && (
                              <Dropdown.Item
                                onClick={() => handleTabChange("alerts")}
                                className={
                                  activeKey === "alerts" ? "active-tab" : ""
                                }
                              >
                                Alert Management
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item
                              onClick={() => handleTabChange("masterData")}
                              className={
                                activeKey === "masterData" ? "active-tab" : ""
                              }
                            >
                              Master Data Management
                            </Dropdown.Item>
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
              </div>
            )}
            {/* {!organization && <div className="mobile-menu mobile-tab"></div>}
            {needBack && (
              <a href="/" className="nav-back-button">
                <FaArrowLeft /> 
              </a>
            )} */}
            {needBack ? (
              <a href={backLink} className="nav-back-button">
                <FaArrowLeft /> 
              </a>
            ) : (
              !organization && <div className="mobile-menu mobile-tab"></div>
            )}
            <Navbar.Brand href="/">
              <a href="/dashboard" className="logo">
                <img src={logo} alt="Ensaras Logo" className="logo desk-logo" />
                <img src={logo1} alt="Ensaras Logo" className="logo mob-logo" />
              </a>
            </Navbar.Brand>

            {/* Custom Toggle Button */}
            {/* <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              onClick={() => toggleSidebar()}
            /> */}
            <div
              onClick={() => toggleSidebar()}
              className="custom-toggle-button"
            >
              <FiAlignJustify />
            </div>

            <Navbar.Offcanvas
              show={showSidebar}
              onHide={closeSidebar}
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Tools
                </Offcanvas.Title>
                {/* Custom Close Button */}
                {/* <div className="custom-close-button" onClick={closeSidebar}>
                  <IoMdClose size={24} />
                </div> */}
                <div onClick={closeSidebar} className="custom-close-button">
                  <FiAlignJustify />
                </div>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  {/* {localStorage.getItem("role") === "Ensaras Admin" && (
                    <button
                      className="nav-btn"
                      onClick={() => {
                        handleOrgAddModal(true);
                      }}
                    >
                      Create Org
                    </button>
                  )} */}
                  <button
                    className="nav-btn"
                    onClick={() => handleResetPasswordModal(true)}
                  >
                    Reset Password
                  </button>
                  {/* {(localStorage.getItem("role") === "Ensaras Admin" ||
                    localStorage.getItem("role") === "Client Admin") && (
                    <button
                      className="nav-btn"
                      onClick={() => handleResendEmailModal(true)}
                    >
                      Resend Email
                    </button>
                  )} */}
                  <button onClick={handleLogout} className="nav-btn">
                    Log out
                  </button>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>

        <ResetPasswordModal
          show={showResetPasswordModal}
          onHide={() => handleResetPasswordModal(false)}
          updateMessage={updateMessage}
        />

        <ResendEmailModal
          show={showResendEmailModal}
          onHide={() => handleResendEmailModal(false)}
          updateMessage={updateMessage}
        />

        <OrgAddModal
          show={showOrgAddModal}
          onHide={() => handleOrgAddModal(false)}
          getOrgInfo={getOrgInfo}
          updateMessage={updateMessage}
        />
      </div>
    );
  }
}

export default AppNavBar;
