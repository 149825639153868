import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import { isEmail, isEmpty } from "validator";
import "./index.css"; // Import the custom CSS file
import { resetPasswordRequest } from "../../hooks/resetPassword";
import MessagePopup from "../messagePopup";

const ResetPasswordRequest = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState("");
  const [variant, setVariant] = useState("danger");
  const [emailError, setEmailError] = useState("");
  const [isBtnClicked, setIsBtnClicked] = useState(false);

  const updateMessage = (type, content) => {
    setMessageType(type);
    setMessage(content);
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    // Validate if the email field is empty
    if (emailValue === "") {
      setEmailError("Email Address is required."); // Set error if empty
    } else {
      setEmailError(false); // Clear error if field is not empty
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isEmpty(email)) {
      setEmailError("Email Address is required.");
      return;
    }

    if (!isEmail(email)) {
      setEmailError("Email is invalid.");
      return;
    }
    setEmailError("");

    // Simulate a request to send the password reset link
    if (email) {
      // Assuming the request is successful
      setIsBtnClicked(true);
      resetPasswordRequest({
        email,
      })
        .then((res) => {
          console.log("res :: ", res.data);
          setVariant(() => "success");
          updateMessage(
            "success",
            "Password reset link has been sent to your email address."
          );
        })
        .catch((error) => {
          updateMessage("error", error.message);
          setIsBtnClicked(false);
        });

      // Simulate error handling
      // Uncomment the following lines to simulate an error
      // setMessage('Failed to send password reset link. Please try again.');
      // setVariant('danger');
    }
  };

  return (
    <Container>
      <Row className="display-screen justify-content-md-center">
        <Col md={6}>
          <div className="forgot-password-container">
            {/* <MessagePopup
              message={message}
              messageType={messageType}
              onClose={() => setMessage("")}
            /> */}
            {message && (
              <Alert variant={variant} className="custom-alert">
                {message}
              </Alert>
            )}
            <h2 className="forgot-password-title">Reset Password</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address <span className="text-danger">*</span></Form.Label>
                <div className="email-fld">
                <Form.Control
                  placeholder="Enter your email address"
                  isInvalid={emailError}
                  value={email}
                  // onChange={(e) => setEmail(e.target.value)}
                  onChange={handleEmailChange}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {emailError}
                </Form.Control.Feedback>
                </div>
              </Form.Group>
            </Form>
              <Button
                variant="primary"
                // type="submit"
                onClick={handleSubmit}
                disabled={isBtnClicked}
                className="forgot-password-btn mt-3"
              >
                Submit
              </Button>
              <div className="mt-3">
              <a href="/" className="back-to-home">
                Back to home
              </a>
              </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPasswordRequest;
