import React, { useState } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'

import { deleteUser } from '../../hooks/userDelete.js'
import './style.css'

const UserDeleteModal = ({
  show,
  onHide,
  username,
  userId,
  getOrgInfo,
  updateMessage,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = () => {
    setIsSubmitting(true);
    deleteUser({ user_id: userId })
      .then((response) => {
        // Close the modal
        onHide();
        if (response) {
          const successMsg = `${response.message}`;
          getOrgInfo();
          updateMessage("success", successMsg, {
            html: true,
            requireConfirmation: true,
          });
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        updateMessage("error", `[${error.message}] Failed to delete user!`);
        setIsSubmitting(false);
      });
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>Are you sure you want to delete the user "{username}"?</p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        {/* <Button variant="secondary" onClick={onHide}>
          Close
        </Button> */}
        <Button
          variant="primary"
          onClick={handleSubmit}
          className="save-team-btn"
          disabled={isSubmitting}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
    )
}

export default UserDeleteModal
