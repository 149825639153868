import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'

import { editOrg } from '../../hooks/orgEdit.js'
import './style.css'

const OrgEditModal = ({
  show,
  onHide,
  orgId,
  orgName,
  getOrgInfo,
  updateMessage,
}) => {
  const [newOrgName, setNewOrgName] = useState(null);
  const [isOrgNameChanged, setIsOrgNameChanged] = useState(true);
  const [error, setError] = useState('')
  useEffect(() => {
    if (orgName) {
      setNewOrgName(orgName);
    }
  }, [orgName]);


  useEffect(() => {
    if (newOrgName !== orgName) {
      setIsOrgNameChanged(false);
    } else {
      setIsOrgNameChanged(true);
    }
  }, [newOrgName]);

  const handleClose = () => {
    setNewOrgName(orgName);
    setError('')
    onHide();
    
  }


  const handleSubmit = () => {
    console.log("newOrgName", newOrgName);
    if (!newOrgName?.trim()) {
      // setError("Organization name is required.")
      setError("Org name is required.")
      return;
    }
    setNewOrgName(newOrgName.trim());
    setIsOrgNameChanged(true);
    editOrg({ org_id: orgId, name: newOrgName })
      .then((response) => {
        // Close the modal
        handleClose();
        setIsOrgNameChanged(false);
        getOrgInfo();
        updateMessage("success", "Organization updated successfully.");
      })
      .catch((error) => {
        updateMessage("error", error.message);
        setIsOrgNameChanged(false);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Org</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Org Name <span className="text-danger">*</span></Form.Label>
          <div className="org-fld">
          <Form.Control
            value={newOrgName}
            onChange={(e) => setNewOrgName(e.target.value)}
            maxLength={100}
            isInvalid={error === "" ? false : true} 
          />
           <Form.Control.Feedback type="invalid">
                                      {error}
                                  </Form.Control.Feedback>
                                  </div>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleSubmit} disabled={isOrgNameChanged} className='save-org-btn'>
          Edit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

//             <Modal.Footer>
//                 <Button variant="secondary" onClick={handleClose}>
//                     Close
//                 </Button>
//                 <Button variant="primary" onClick={handleSubmit} disabled={isOrgNameChanged} className="submitButton">
//                     Edit
//                 </Button>
//             </Modal.Footer>
//         </Modal>
//     )
// }

export default OrgEditModal
