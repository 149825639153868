import React, { useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";

import { addTeam } from "../../hooks/teamAdd.js";
import "./style.css";
import moment from "moment-timezone";
import Select from "react-select";

const TeamAddModal = ({ show, onHide, orgId, getOrgInfo, updateMessage }) => {
  const [teamName, setTeamName] = useState(null);
  const [timezone, setTimezone] = useState("Asia/Kolkata");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getTimezones = () => {
    return moment.tz.names().map((timezone) => ({
      value: timezone,
      label: timezone,
    }));
  };

  const timezones = getTimezones();

  // Handle form submission
  const handleSubmit = () => {
    const stripedTeamName = teamName?.trim();
    if (!stripedTeamName) {
      setError("Team name is required.");
      return;
    }
    setTeamName(stripedTeamName);
    setIsSubmitting(true);
    addTeam({ org_id: orgId, name: teamName, timezone: timezone })
      .then((response) => {
        // Close the modal
        handleHide();
        setIsSubmitting(false);
        getOrgInfo();
        updateMessage("success", "Team added successfully");
      })
      .catch((error) => {
        updateMessage("error", error.message);
        setIsSubmitting(false);
      });
  };

  const handleHide = () => {
    setTeamName(null);
    setTimezone("Asia/Kolkata");
    setError("");
    onHide();
  };

  return (
    <Modal show={show} onHide={handleHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Create Team</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>
              Team Name <span className="text-danger">*</span>
            </Form.Label>
            <div className="team-fld mb-3">
              <Form.Control
                isInvalid={error === "" ? false : true}
                placeholder="Enter Team Name"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                maxLength={100}
              />
              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>Timezone</Form.Label>
            <div className="timezone-select">
              <Select
                value={timezones.find((tz) => tz.value === timezone)}
                onChange={(option) => setTimezone(option.value || "")}
                options={timezones}
                placeholder="Select a timezone"
                classNamePrefix="react-select"
              />
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleSubmit}
          className="save-team-btn"
          disabled={isSubmitting}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TeamAddModal;
